<template>
  <button
    class="relative font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500 enabled:hover:transition-colors enabled:hover:duration-200"
    :class="{
      'rounded-xs': isLinkButton,
      'rounded-md': !isLinkButton,

      'px-lg py-md': size === 'sm' && !isLinkButton,
      'px-3.5 py-2.5': size === 'md' && !isLinkButton,
      'px-xl py-2.5': size === 'lg' && !isLinkButton,

      'text-sm': size === 'sm' || size === 'md',
      'text-base': size === 'lg',

      'bg-brand-600 text-white enabled:hover:bg-brand-700': variant === 'primary',
      'disabled:border disabled:border-gray-200 disabled:bg-gray-100 disabled:shadow-xs': variant === 'primary' && !loading,

      // Secondary
      'border border-gray-300 bg-white text-gray-700 enabled:hover:bg-gray-50': variant === 'secondary-gray',
      'border border-brand-300 bg-white text-brand-700 enabled:hover:bg-brand-50': variant === 'secondary-color',
      'disabled:border-gray-200 disabled:shadow-xs': (variant === 'secondary-gray' || variant === 'secondary-color') && !loading,

      // Link-color
      'text-brand-700 enabled:hover:text-brand-800': variant === 'link-color',

      // States
      'disabled:text-gray-400': disabled && !loading,
    }"
    :disabled="disabled || loading"
  >
    <span
      v-if="loading"
      class="absolute inset-0 flex items-center justify-center"
    >
      <img
        alt=""
        src="~/assets/images/loading-circle.svg"
        class="size-1/2 animate-spin"
      >
    </span>
    <span
      class="flex items-center justify-center"
      :class="{
        'invisible': loading,
        'gap-xs': size === 'sm' || size === 'md',
        'gap-sm': size === 'lg',
      }"
    >
      <UiIcon
        v-if="leadingIcon"
        :name="leadingIcon"
        class="size-5"
      />
      <span>
        <slot />
      </span>
      <UiIcon
        v-if="trailingIcon"
        :name="trailingIcon"
        class="size-5"
      />
    </span>
  </button>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    size?: 'sm' | 'md' | 'lg'
    variant?: 'primary' | 'secondary-gray' | 'secondary-color' | 'link-color'
    disabled?: boolean
    loading?: boolean
    leadingIcon?: string
    trailingIcon?: string
  }>(),
  {
    size: 'lg',
    variant: 'primary',
  },
)

const isLinkButton = computed(() => props.variant === 'link-color')
</script>
